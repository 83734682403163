.altai-theme-content #bodyView .container-content .email-template {
  /* HTML5 display-role reset for older browsers */
}
.altai-theme-content #bodyView .container-content .email-template html,
.altai-theme-content #bodyView .container-content .email-template body,
.altai-theme-content #bodyView .container-content .email-template div,
.altai-theme-content #bodyView .container-content .email-template span,
.altai-theme-content #bodyView .container-content .email-template applet,
.altai-theme-content #bodyView .container-content .email-template object,
.altai-theme-content #bodyView .container-content .email-template iframe,
.altai-theme-content #bodyView .container-content .email-template h1,
.altai-theme-content #bodyView .container-content .email-template h2,
.altai-theme-content #bodyView .container-content .email-template h3,
.altai-theme-content #bodyView .container-content .email-template h4,
.altai-theme-content #bodyView .container-content .email-template h5,
.altai-theme-content #bodyView .container-content .email-template h6,
.altai-theme-content #bodyView .container-content .email-template p,
.altai-theme-content #bodyView .container-content .email-template blockquote,
.altai-theme-content #bodyView .container-content .email-template pre,
.altai-theme-content #bodyView .container-content .email-template abbr,
.altai-theme-content #bodyView .container-content .email-template acronym,
.altai-theme-content #bodyView .container-content .email-template address,
.altai-theme-content #bodyView .container-content .email-template big,
.altai-theme-content #bodyView .container-content .email-template cite,
.altai-theme-content #bodyView .container-content .email-template code,
.altai-theme-content #bodyView .container-content .email-template del,
.altai-theme-content #bodyView .container-content .email-template dfn,
.altai-theme-content #bodyView .container-content .email-template em,
.altai-theme-content #bodyView .container-content .email-template img,
.altai-theme-content #bodyView .container-content .email-template ins,
.altai-theme-content #bodyView .container-content .email-template kbd,
.altai-theme-content #bodyView .container-content .email-template q,
.altai-theme-content #bodyView .container-content .email-template s,
.altai-theme-content #bodyView .container-content .email-template samp,
.altai-theme-content #bodyView .container-content .email-template small,
.altai-theme-content #bodyView .container-content .email-template strike,
.altai-theme-content #bodyView .container-content .email-template strong,
.altai-theme-content #bodyView .container-content .email-template sub,
.altai-theme-content #bodyView .container-content .email-template sup,
.altai-theme-content #bodyView .container-content .email-template tt,
.altai-theme-content #bodyView .container-content .email-template var,
.altai-theme-content #bodyView .container-content .email-template b,
.altai-theme-content #bodyView .container-content .email-template u,
.altai-theme-content #bodyView .container-content .email-template i,
.altai-theme-content #bodyView .container-content .email-template center,
.altai-theme-content #bodyView .container-content .email-template dl,
.altai-theme-content #bodyView .container-content .email-template dt,
.altai-theme-content #bodyView .container-content .email-template dd,
.altai-theme-content #bodyView .container-content .email-template ol,
.altai-theme-content #bodyView .container-content .email-template ul,
.altai-theme-content #bodyView .container-content .email-template li,
.altai-theme-content #bodyView .container-content .email-template fieldset,
.altai-theme-content #bodyView .container-content .email-template form,
.altai-theme-content #bodyView .container-content .email-template label,
.altai-theme-content #bodyView .container-content .email-template legend,
.altai-theme-content #bodyView .container-content .email-template table,
.altai-theme-content #bodyView .container-content .email-template caption,
.altai-theme-content #bodyView .container-content .email-template tbody,
.altai-theme-content #bodyView .container-content .email-template tfoot,
.altai-theme-content #bodyView .container-content .email-template thead,
.altai-theme-content #bodyView .container-content .email-template tr,
.altai-theme-content #bodyView .container-content .email-template th,
.altai-theme-content #bodyView .container-content .email-template td,
.altai-theme-content #bodyView .container-content .email-template article,
.altai-theme-content #bodyView .container-content .email-template aside,
.altai-theme-content #bodyView .container-content .email-template canvas,
.altai-theme-content #bodyView .container-content .email-template details,
.altai-theme-content #bodyView .container-content .email-template embed,
.altai-theme-content #bodyView .container-content .email-template figure,
.altai-theme-content #bodyView .container-content .email-template figcaption,
.altai-theme-content #bodyView .container-content .email-template footer,
.altai-theme-content #bodyView .container-content .email-template header,
.altai-theme-content #bodyView .container-content .email-template hgroup,
.altai-theme-content #bodyView .container-content .email-template menu,
.altai-theme-content #bodyView .container-content .email-template nav,
.altai-theme-content #bodyView .container-content .email-template output,
.altai-theme-content #bodyView .container-content .email-template ruby,
.altai-theme-content #bodyView .container-content .email-template section,
.altai-theme-content #bodyView .container-content .email-template summary,
.altai-theme-content #bodyView .container-content .email-template time,
.altai-theme-content #bodyView .container-content .email-template mark,
.altai-theme-content #bodyView .container-content .email-template audio,
.altai-theme-content #bodyView .container-content .email-template video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.altai-theme-content #bodyView .container-content .email-template article,
.altai-theme-content #bodyView .container-content .email-template aside,
.altai-theme-content #bodyView .container-content .email-template details,
.altai-theme-content #bodyView .container-content .email-template figcaption,
.altai-theme-content #bodyView .container-content .email-template figure,
.altai-theme-content #bodyView .container-content .email-template footer,
.altai-theme-content #bodyView .container-content .email-template header,
.altai-theme-content #bodyView .container-content .email-template hgroup,
.altai-theme-content #bodyView .container-content .email-template menu,
.altai-theme-content #bodyView .container-content .email-template nav,
.altai-theme-content #bodyView .container-content .email-template section {
  display: block;
}
.altai-theme-content #bodyView .container-content .email-template .centertext {
  text-align: center;
}
.altai-theme-content #bodyView .container-content .email-template body {
  line-height: 1;
}
.altai-theme-content #bodyView .container-content .email-template ol,
.altai-theme-content #bodyView .container-content .email-template ul {
  list-style: none;
}
.altai-theme-content #bodyView .container-content .email-template blockquote,
.altai-theme-content #bodyView .container-content .email-template q {
  quotes: none;
}
.altai-theme-content #bodyView .container-content .email-template blockquote:before,
.altai-theme-content #bodyView .container-content .email-template blockquote:after,
.altai-theme-content #bodyView .container-content .email-template q:before,
.altai-theme-content #bodyView .container-content .email-template q:after {
  content: '';
  content: none;
}
.altai-theme-content #bodyView .container-content .email-template table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
.altai-theme-content #bodyView .container-content .email-template table td,
.altai-theme-content #bodyView .container-content .email-template table th,
.altai-theme-content #bodyView .container-content .email-template table tr {
  background: inherit;
}
.altai-theme-content #bodyView .container-content .email-template table td :nth-child(odd),
.altai-theme-content #bodyView .container-content .email-template table th :nth-child(odd),
.altai-theme-content #bodyView .container-content .email-template table tr :nth-child(odd) {
  background: inherit;
}
.altai-theme-content #bodyView .container-content .email-template table tr:nth-child(even),
.altai-theme-content #bodyView .container-content .email-template table tr:nth-child(odd) {
  background: initial;
}
@media only screen {
  .altai-theme-content #bodyView .container-content .email-template html {
    min-height: 100%;
    background: #EFEFEF;
  }
}
@media only screen and (max-width: 910px) {
  .altai-theme-content #bodyView .container-content .email-template .small-float-center {
    margin: 0 auto !important;
    float: none !important;
    text-align: center !important;
  }
}
@media only screen and (max-width: 910px) {
  .altai-theme-content #bodyView .container-content .email-template .hide-for-large {
    display: block !important;
    width: auto !important;
    overflow: visible !important;
    max-height: none !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }
}
@media only screen and (max-width: 910px) {
  .altai-theme-content #bodyView .container-content .email-template table.body table.container .hide-for-large {
    display: table !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 910px) {
  .altai-theme-content #bodyView .container-content .email-template table.body table.container .show-for-large {
    display: none !important;
    width: 0;
    mso-hide: all;
    overflow: hidden;
  }
}
@media only screen and (max-width: 910px) {
  .altai-theme-content #bodyView .container-content .email-template table.body img {
    width: auto;
    height: auto;
  }
  .altai-theme-content #bodyView .container-content .email-template table.body center {
    min-width: 0 !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.body .container {
    width: 95% !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.body .columns {
    height: auto !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.body .columns .columns {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.body .collapse .columns {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .altai-theme-content #bodyView .container-content .email-template th.small-6 {
    display: inline-block !important;
    width: 50% !important;
  }
  .altai-theme-content #bodyView .container-content .email-template th.small-12 {
    display: inline-block !important;
    width: 100% !important;
  }
  .altai-theme-content #bodyView .container-content .email-template .columns th.small-12 {
    display: block !important;
    width: 100% !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.menu {
    width: 100% !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.menu td,
  .altai-theme-content #bodyView .container-content .email-template table.menu th {
    width: auto !important;
    display: inline-block !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.menu.vertical td,
  .altai-theme-content #bodyView .container-content .email-template table.menu.vertical th {
    display: block !important;
  }
  .altai-theme-content #bodyView .container-content .email-template table.menu[align=center] {
    width: auto !important;
  }
}
